<template>
    <v-card class="pa-3" max-width="800">
        <h1>{{ $t("confirmNewEmail.title") }}</h1>
        <v-subheader>{{ $t("confirmNewEmail.subheader") }}</v-subheader>
        <div v-if="isLoading">
            <v-progress-circular indeterminate></v-progress-circular>
        </div>
        <div v-else>
            <div v-if="success">
                <v-alert type="success">{{ $t('confirmNewEmail.success') }}</v-alert>
            </div>
            <div v-else>
                <v-alert type="error">{{ errorMessage }}</v-alert>
            </div>
        </div>
    </v-card>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import { ILLI_AUTH_SERVER_URL } from '../config'

export default Vue.extend({
    name: "ConfirmNewEmail",
    data () {
        return {
            isLoading: true,
            success: false,
            errorMessage: ''
        }
    },
    beforeMount () {
        axios
        .patch(`${ILLI_AUTH_SERVER_URL}/user/confirm/email`, { key: this.$route.params.key })
        .then(response => {
            if(response.status === 200) {
                this.success = true;
                setTimeout(() => {
                    this.$store.dispatch('logout'); // Dispatch action to log out user
                    this.$router.replace({
                        name: 'login'
                    })
                }, 5000);
                this.isLoading = false;
            }
        })
        .catch(error => {
            if (error.response && error.response.status === 409) {
                this.errorMessage = error.response.data.error;
                this.isLoading = false;
                return; // Return early
            } else if (error.response && error.response.data.error) {
                this.errorMessage = error.response.data.error;
            } else {
                console.error(error);
            }
            this.isLoading = false;
        })
        .finally(() => {
            this.isLoading = false;
        });
    }
})
</script>